$primary: #f7c204;
$bg_primary: #e9e1d6;
$secondary: white;
$text_primary: black;
$text_secondary: white;

::-webkit-scrollbar {
    width: 8px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.light-shadow {
    -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.2));
}
.light-shadow-left-right {
    -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
}
.bg-yellow {
    background-color: #f4c22f;
}
.dropdown-yellow {
    background-color: #f4c22f;
}
.dropdown-yellow:focus {
    background-color: #f4c22f;
    border: 0;
    box-shadow: none;
}
.normal-shadow {
    -webkit-filter: drop-shadow(6px 6px 3px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(6px 6px 3px rgba(0, 0, 0, 0.2));
}
.normal-shadow-left-right {
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}
.light-shadow-bottom {
    -webkit-filter: drop-shadow(3px 0px 3px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(3px 0px 3px rgba(0, 0, 0, 0.2));
}

.chat-input {
    background-color: $secondary;
    border: 0;
    color: $text_primary !important;
}
.chat-input::placeholder {
    color: $text_primary;
}
.chat-input:focus {
    background-color: $secondary;
    border: 0;
    -webkit-box-shadow: inset 0 0 0 0 #ecc961;
    box-shadow: inset 0 0 0 0 #ecc961;
}
.form-bevel {
    background-color: #c6aa59;
    -webkit-box-shadow: inset 2px 7px 9px 2px #ecc961;
    box-shadow: inset 2px 7px 9px 2px #ecc961;
    border: 0;
    border-radius: 10px;
    height: 50px;
}
.form-bevel::placeholder {
    color: #4f4424;
}
.form-bevel:focus {
    background-color: #c6aa59;
    -webkit-box-shadow: inset 3px 9px 13px 2px #ecc961;
    box-shadow: inset 3px 9px 13px 2px #ecc961;
}
.pointer-on-hover {
    cursor: pointer;
}

.nav-bar-items {
    cursor: pointer;
}
.nav-bar-items:hover {
    filter: brightness(98%);
}
.coach-name-heading {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}
.coach-bio{
    height:50px;
    overflow: hidden;
}
.coach-div{
    height:300px;
}
@media (max-width:768px){
    .coach-div{
        height:190px;
    }
}

.coach-span {
    width: 25%;
    height: 100%;
    border-radius: 10px;
}
.sessionIcons {
    height: 35px;
    width: 35px;
}
.chat-card {
    border-radius: 40px;
    background-color: $secondary;
}
.chat-span {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}
.coachImageLandingPage {
    max-height: 150px;
    max-width: 150px;
}
.coachSpanLandingPage {
    height: 150px;
    width: 150px;
    border-radius: 8px;
    background-color: rgb(27, 27, 27);
}
.coachNameLandingPage {
    text-shadow: 0 0 5px #000000;
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: #000000;
    bottom: 30px;
    font-size: 17px;
}
@keyframes __zoomOut {
    0% {
        height: 72%;
        width: 72%;
    }
    100% {
        height: 81%;
        width: 81%;
    }
}
.landingPageSkillCard:hover {
    height: 81%;
    width: 81%;
    animation: __zoomOut;
    animation-duration: 0.5s;
}
.landingPageSkillCard {
    height: 72%;
    width: 72%;
}

@media (max-width: 768px) {
    @keyframes __zoomOut {
        0% {
            height: 80%;
            width: 80%;
        }
        100% {
            height: 90%;
            width: 90%;
        }
    }
    .landingPageSkillCard:hover {
        height: 90%;
        width: 90%;
        animation: __zoomOut;
        animation-duration: 0.5s;
    }
    .landingPageSkillCard {
        height: 80%;
        width: 80%;
    }
}

.landingPageSkillCardSpanWrapper {
    width: 20%;
}
@media (min-width: 768px) {
    .sessionIcons {
        height: 50px;
        width: 50px;
    }
    .chat-span {
        border-radius: 50%;
        height: 100px;
        width: 100px;
    }

    .coachImageLandingPage {
        max-height: 200px;
        max-width: 200px;
    }
    .coachSpanLandingPage {
        height: 200px;
        width: 200px;
        border-radius: 8px;
    }
    @keyframes margin {
        from {
            margin-top: 0;
        }
        to {
            margin-top: 5px;
        }
    }
    .coachCardLandingPageDiv:hover {
        margin-top: 5px;
        animation-name: margin;
        animation-duration: 0.5s;
    }
    .coachNameLandingPage {
        text-shadow: 0 0 5px #000000;
        -webkit-text-stroke-width: 0.3px;
        -webkit-text-stroke-color: #000000;
        bottom: 40px;
        font-size: 20px;
    }
}
.noselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.invert {
    filter: invert(100%);
}

.tri-right.top-left:after {
    content: "";
    position: absolute;
    right: auto;
    left: -10px;
    top: 0;
    bottom: auto;
    border: 10px solid;
    border-color: white transparent transparent transparent;
}
.tri-right.top-right:after {
    content: "";
    position: absolute;
    right: -10px;
    left: auto;
    top: 0;
    bottom: auto;
    border: 10px solid;
    border-color: $secondary transparent transparent transparent;
}
.rounded20 {
    border-radius: 20px;
}
.form-input {
    background: $bg_primary;
    height: 53px;
    border-color: $text_primary;
    font-weight: bold;
    font-style: normal;
    font-size: 16px;
    color: $text_primary;
}
.form-input::placeholder {
    font-weight: bold;
    font-style: normal;
    font-size: 16px;
    color: $text_primary;
}
.form-input:focus {
    color: $text_primary;
    background: $bg_primary;
    border-color: $text_primary;
    -webkit-box-shadow: inset 0 0 0 0 #ecc961;
    box-shadow: inset 0 0 0 0 #ecc961;
}

.modal-contents {
    background: #f4f4f4;
    border-radius: 20px;
}
.modal-heading {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: $text_primary;
}
.role-selection-modal-heading {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    color: $text_primary;
}
.register-modal-heading {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    color: $text_secondary;
}
.modal-text {
    color: $text_primary;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}
.modal-button-text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
}
.modal-button {
    background: $primary;
    border-radius: 5px;
    height: 53px;
}
.role-selection-button-1 {
    background: $bg_primary;
    height: 53px;
    border: 1px solid #929292;
    box-sizing: border-box;
    border-radius: 5px;
}
.role-selection-button-2 {
    height: 53px;
    border-radius: 5px;
    background: $primary;
    color: $text_primary;
}
.role-selection-button-2:hover {
    color: $text_primary;
}
.role-selection-button-3 {
    border-radius: 5px;
    background: $primary;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: $text_primary;
    -webkit-box-shadow: inset 0 0 0 0 #ecc961;
    box-shadow: inset 0 0 0 0 #ecc961;
}
.role-selection-button-3:hover {
    color: $text_primary;
}

.role-selection-button-3:focus {
    -webkit-box-shadow: inset 0 0 0 0 #ecc961;
    box-shadow: inset 0 0 0 0 #ecc961;
}
.role-selection-button-4:focus {
    -webkit-box-shadow: inset 0 0 0 0 #ecc961;
    box-shadow: inset 0 0 0 0 #ecc961;
}
.role-selection-button-4 {
    border-radius: 5px;
    background: $bg_primary;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    -webkit-box-shadow: inset 0 0 0 0 #ecc961;
    box-shadow: inset 0 0 0 0 #ecc961;
    /* identical to box height */

    color: $text_primary;
}
.role-selection-button-4:hover {
    color: $text_primary;
}
.modal-header {
    border: 0;
    text-align: center;

    padding-bottom: 0;
}
.searchModalHeading {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    color: $text_primary;
}
.number-circle-border {
    width: 25px;
    height: 25px;
    line-height: 17px;
    border-radius: 50%;
    text-align: center;
    border: 3px solid $primary;
}
.number-circle-tick {
    width: 25px;
    height: 25px;
    line-height: 17px;
    border-radius: 50%;
    text-align: center;
    border: 3px solid $primary;
    background: $primary;
}
.number-circle {
    width: 25px;
    height: 25px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
}
.number-line {
    border: 1px solid #ffffff;
}
.number-circle-caption {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: $text_primary;
}
.register-modal-tab {
    height: 55px;
    background: #f8cb45;
    border-radius: 5px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 132.69%;
    /* or 19px */

    letter-spacing: 0.125em;

    color: #000000;
}
.user-profile {
    font-family: Roboto, sans-serif;
}
.rating-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.user-name {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;

    color: $text_primary;
}
.about-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    color: $text_primary;
}
.bio-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    color: $text_primary;
}
.user-profile-session-card {
    background: #242526;
    border: 2px solid #f8cb45;
    border-radius: 10px;
}
.user-profile-name {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}
.upcoming-sessions-text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
}
.coachNameSessionCard {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
}
.dateString {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}
.timeString {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 16px;
}
.searchInput {
    background-color: transparent;
    border: 0;
}
.searchInput:focus {
    border: 0;
    background-color: transparent;
    -webkit-box-shadow: inset 0 0 0 0 #ecc961;
    box-shadow: inset 0 0 0 0 #ecc961;
}
.searchInput::placeholder {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}
.searchPopupHeading {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    color: #ffffff;
}
.coach-card-price {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    text-shadow: 0 0 5px #000000;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: #000000;
    color: #ffffff;
}
.session-starts-in-text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.countdown-text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
}
.coaching-since-text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    color: #aaaaaa;
}
.homePopup {
    background-color: $secondary !important;
    width: 100% !important ;
}
.homeModalDialog {
    max-width: 90vw;
}

.homePageSkillCard {
    height: 55%;
    width: 55%;
}
.popupCard {
    width: 20%;
    height: 20%;
}
@media (max-width: 568px) {
    .homePageSkillCard {
        height: 95%;
        width: 95%;
    }
    .popupCard {
        width: 33%;
        height: 33%;
    }
}

.edit-teaching-skills:hover {
    background-color: transparent;
}
.btn {
    border-width: 1px !important;
    border-color: $text_primary;
}
.btn-outline-success {
    border-width: 1px !important;
    border-color: $primary;
}
.darken{
    filter: brightness(50%);
}