@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";



.landingPage {
  .navigationBar {
    height: 120px;
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
    @include media-breakpoint-down(md) {
      & {
        height: 75px;
      }
    }

    .container {
      display: flex;
      align-items: center;
      height: 100%;

      .logoSection {
        display: flex;
        flex-grow: 1;
        align-items: center;

        .logo {
          height: 51px;
          @include media-breakpoint-down(md) {
            & {
              height: 31px;
            }
          }
        }

        .logoText {
          font-family: Poppins, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: #FACE71;
          padding: 10px;
          @include media-breakpoint-down(md) {
            & {
              padding: 8px;
              text-align: left;
              font-size: 15px;
            }
          }
        }
      }

      .buttonSection {
        display: flex;
        align-items: center;

        .signInButton {
          font-family: Poppins, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          color: rgba(255, 255, 255, 0.95);
          background: transparent;
          border: 0;
          margin: 20px;
          @include media-breakpoint-down(md) {
            & {
              font-size: 12px;
              width: 70px;
              margin: 5px;
            }
          }
        }

        .signUpButton {
          font-family: Poppins, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          width: 160px;
          color: rgba(0, 0, 0, 0.8);
          background: #FFFFFF;
          border-radius: 15px;
          border: 0;
          margin: 20px;
          height: 45px;
          @include media-breakpoint-down(md) {
            & {
              font-size: 12px;
              line-height: 12px;
              width: 70px;
              height: 100/160*45px;
              margin: 5px;
            }
          }
        }

      }
    }
  }

  .banner {
    height: 9/16*100vw;
    @include media-breakpoint-down(sm){
      background-image: url("../../static/bannerBackgroundMobile.png");
    }
    @include media-breakpoint-down(sm) {
      height: 650px;
    }
    #bannerVideo{
      @include media-breakpoint-down(sm){
        display: none;
      }
      position: absolute;
      left:0;
      top:0;
      width: 100%;
      z-index: -1;
    }
    .container-fluid {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
      .items {
        .textBox {
          .text {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 90px;
            color: #000000;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: white;
            @include media-breakpoint-down(md) {
              font-size: 75px;
            }
            @include media-breakpoint-down(sm) {
              font-size: 60px;
            }
            @include media-breakpoint-down(xs) {
              font-size: 40px;
            }
            span {
              padding: 0 3px;
            }
            #skills, #share {
              color: #FACE71;

              -webkit-text-stroke-color: black;
            }
          }
        }
      }
    }
  }
  .roleSelection{
    .container{
      display: flex;
      min-height:510px;
      margin-bottom: 0;
      margin-top: 0;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .role-card{
        display:flex;
        flex-direction: column;
        font-family: Poppins,sans-serif;
        font-style: normal;
        background: #FFFFFF;
        max-width: 500px;
        min-height: 380px;
        border: 1px solid #C7C6C6;
        box-sizing: border-box;
        box-shadow: 0 5px 4px rgba(0, 0, 0, 0.25);
        padding: 50px;
        margin:20px;
        @include media-breakpoint-down(sm){
          padding: 20px;
          width:100%;
          margin:20px 5px;
        }
        .heading{
          font-weight: bold;
          font-size: 48px;
          line-height: 72px;
          color: #343333;
        }
        .body{
          font-weight: normal;
          font-size: 22px;
          line-height: 33px;
          text-align: center;
          flex-grow: 1;
          color: #2C2B2B;
        }
      }
    }
  }
  .categories{
    min-height: 520px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.95);
    font-family: Roboto Slab, serif;
    font-style: normal;
    .heading{
      font-weight: bold;
      font-size: 36px;
      line-height: 47px;
      text-align: center;
      color: #FACE71;
    }
    .line{
      margin:20px 0;
      width: 340px;
    }
    .sub-heading{
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;

      color: #E3DBDB;
      margin: 10px;

    }

  }
  .landingPageButton {
    height: 80px;
    width: 220px;

    box-shadow: 0 5px 2px rgba(0, 0, 0, 0.25);
    border: 0;
    font-family: Poppins, sans-serif;
    font-style: normal;
    border-radius: 25px;
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    background: white;
    @include media-breakpoint-down(md) {
      & {
        font-size: 18px;
        line-height: 18px;
        width: auto;
      }
    }

    &-bookNow {
      @extend .landingPageButton;
      color: rgba(0, 0, 0, 0.75);
      background: #FECE66;
      margin: 15px;
      @include media-breakpoint-down(md) {
        width: auto;
        height: auto;
        padding: 25px;
        margin: 7px;
      }
      @include media-breakpoint-down(sm){
        padding: 20px;
      }
    }

    &-explore {
      @extend .landingPageButton;
      color: rgba(0, 0, 0, 0.75);
      border: 2px solid #FECE66;
      box-sizing: border-box;
      margin: 15px;

      @include media-breakpoint-down(md) {
        width: auto;
        height: auto;
        padding: 25px;
        margin: 7px;
      }
      @include media-breakpoint-down(sm){
        padding: 20px;
      }
    }

    &-getStarted {
      @extend .landingPageButton;
      color: rgba(0, 0, 0, 0.8);
      background: #FACE71;
      width: 100%;


    }
  }
  .connectLive{
    .container-fluid{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding-top:70px;
      padding-bottom:70px;
      .image{
        .videoImage{
          width: 100%;
        }
      }
      .text{
        font-family: Poppins, sans-serif;
        font-style: normal;
        text-align: left;
        @include media-breakpoint-down(md){
          text-align: center;
        }
        .heading{
          font-weight: bold;
          font-size: 48px;
          @include media-breakpoint-down(md){
            font-size: 36px;
          }
          line-height: 115.38%;
          margin: 30px 0;
          /* or 55px */
          letter-spacing: -0.02em;

          color: #000000;
        }
        .body{
          font-weight: 500;
          font-size: 26px;
          @include media-breakpoint-down(md){
            font-size: 20px;
          }
          line-height: 39px;
          margin: 30px 0;

          color: #000000;
        }
      }
    }
  }
  .faq{
    background: rgba(0, 0, 0, 0.95);
    .container{
      padding:50px;
      @include media-breakpoint-down(sm){
        padding: 10px;
      }
      .heading{
        font-family: Roboto Slab, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 47px;

        color: #FACE71;
        margin:15px;
        @include media-breakpoint-down(sm){
          font-size: 24px;
          line-height: 28px;
        }
      }
      .line{
        margin:20px 0;
        width: 100%;
      }
      .faqs{
        margin-top: 50px;
        .faq-card{
          margin: 25px 0;
          border-radius: 12px!important;
          background: #575757!important;

          color: #FACE71;
          .card-header{
            background: #575757 !important;
            border-radius: 12px!important;
            font-family: Roboto Slab, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            padding: 20px 20px 20px 50px;
            @include media-breakpoint-down(xs){
              padding:20px;
            }
            /* identical to box height */

            color: #FCFCFC;
          }
          .card-body{
            padding: 20px 20px 20px 50px;
          }

        }
      }
    }
  }
  footer{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 80px;
    margin: 50px 0;
    font-family: Roboto Slab, sans-serif;
    font-style: normal;
    .details{

      .slogan{
        margin: 20px 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;

        color: #000000;
      }
      .address,.email,.phone{
        margin: 10px 0;
        text-align: left;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;

        color: #000000;
      }
    }
    .footer-columns{
      margin-top: 130px;
      flex-wrap: wrap;

      @include media-breakpoint-down(md){
        margin: 50px 0;
      }
      display: flex;
      flex-grow: 1;
      justify-content: space-around;
    }
    .column-heading{
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;

      /* identical to box height */

      color: #000000;
    }
    .detailIcons{
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
  }
}
